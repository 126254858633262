<template>
  <div>
    <mainChart />
    <afterChartSummary />
  </div>
</template>

<script>

import mainChart from './charts/MainChart.vue'
import afterChartSummary from './charts/AfterChartSummary.vue'

export default {
  components: {
    mainChart,
    afterChartSummary,
  },
}
</script>

<style>

</style>
